.specialism-form {
    $specialism-form: &;

    &__item {
        position: relative;
        padding: vw-calc(7);
        padding-right: vw-calc(75);
        margin-left: vw-calc(-7);
        margin-bottom: vw-calc(10);
        transition: $transition-default;

        &:hover {
            background: map-deep-get($colors, "gray", "dark");

            #{$specialism-form}__edit-button,
            #{$specialism-form}__add-button {
                opacity: 1;
            }
        }
    }

    &__input {
        padding: 0;
        border: 0;
        height: vw-calc(25);
        background: transparent;
        cursor: pointer;

        &:focus {
            cursor: text;
        }
    }

    &__edit-button,
    &__add-button {
        position: absolute;
        top: vw-calc(7);
        width: vw-calc(25);
        height: vw-calc(25);
        padding: vw-calc(5);
        border-radius: 50%;
        background: map-deep-get($colors, "white", "default");
        opacity: 0;
        transition: $transition-default;
        cursor: pointer;

        svg {
            width: vw-calc(16);
            height: vw-calc(16);
        }
    }

    &__edit-button {
        right: vw-calc(7);
    }

    &__add-button {
        right: vw-calc(34);
    }

    &__submit-button {
        display: none;

        &--active {
            display: block;
        }
    }
}