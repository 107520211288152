.titleslide {
    display: flex;
    flex-flow: column;
    justify-content: center;
    min-height: 100vh;
    height: 100vh;
    margin: 0 10%;
    padding: vw-calc(80) 0;

    &__caption {
        color: map-deep-get($colors, "brand", "primary");
    }
}