.footer {
    position: fixed;
    z-index: 14;
    bottom: 0;
    width: 100%;
    transition: $transition-default;
    $class: &;

    &--hidden {
        bottom: vw-calc(-100);
    }

    &__content {
        position: relative;
        margin: 0 auto;
        width: 80%;
        background: map-deep-get($colors, "gray", "light");
        padding: vw-calc(16) vw-calc(60) vw-calc(16) vw-calc(76);
    }

    &__progressbar {
        position: absolute;
        top: vw-calc(19);
        left: vw-calc(76);
        right: vw-calc(60);
    }

    &__progressindicator {
        position: absolute;
        width: 0;
        height: vw-calc(2);
        background: map-deep-get($colors, "brand", "primary");
    }

    &__progressoffset {
        position: absolute;
        left: vw-calc(-16);
        width: vw-calc(16);
        height: vw-calc(2);
        background: map-deep-get($colors, "brand", "primary");
    }

    &__progressbackground {
        position: absolute;
        left: vw-calc(-16);
        right: 0;
        height: vw-calc(2);
        background: map-deep-get($colors, "white", "default");
    }

    &__sections {
        display: flex;
        justify-content: space-between;
    }

    &__section {
        position: relative;
        z-index: 1;

        &:hover {
            #{$class}__section-link {
                opacity: 1;
                height: vw-calc(60);
            }

            #{$class}__section-name {
                transform: scale(1) rotate(0);
            }
        }

        &::before {
            content: "";
            display: block;
            height: vw-calc(8);
            width: vw-calc(8);
            border-radius: 50%;
            background: map-deep-get($colors, "brand", "primary");
        }
    }

    &__section-link {
        position: absolute;
        left: vw-calc(4);
        bottom: 0;
        opacity: 0;
        width: 100%;
        height: vw-calc(20);
        transition: $transition-default;
    }

    &__section-name {
        position: relative;
        display: inline-block;
        margin-bottom: vw-calc(18);
        padding: vw-calc(10);
        color: map-deep-get($colors, "white", "default");
        background: map-deep-get($colors, "brand", "primary");
        transform: scale(0) rotate(90deg);
        transform-origin: 0 100%;
        transition: $transition-default;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: vw-calc(-12);
            border-top: vw-calc(14) solid map-deep-get($colors, "brand", "primary");
            border-right: vw-calc(22) solid transparent;
        }
    }
}