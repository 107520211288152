.navigation-section-index-list-item {
    $elm: &;

    &__link {
        display: flex;
        align-items: stretch;
        height: auto;
        padding: 0 vw-calc(10) 0 vw-calc(15);
        margin-bottom: vw-calc(10);
        font-size: rem-calc(20);
        line-height: 1.15em;
        font-weight: 600;
        text-align: left;
        transition: $transition-default;
        color: map-deep-get($colors, "black", "default");
        background: map-deep-get($colors, "gray", "light");
        border: vw-calc(2) solid map-deep-get($colors, "gray", "light");

        &:hover {
            background: map-deep-get($colors, "brand", "primary");
            border: vw-calc(2) solid map-deep-get($colors, "brand", "primary");
            color: map-deep-get($colors, "white", "default");

            #{$elm}__bookmark svg path {
                fill: map-deep-get($colors, "white", "default");
            }

            #{$elm}__open svg path {
                fill: map-deep-get($colors, "black", "default");
            }

            #{$elm}__open-text {
                width: rem-calc(50);
            }
        }

        &--light {
            background: map-deep-get($colors, "white", "default");
            border: vw-calc(2) solid map-deep-get($colors, "white", "default");
        }

        &--bookmarked {
            border: vw-calc(2) solid map-deep-get($colors, "brand", "primary");

            &:hover {
                border: vw-calc(2) solid map-deep-get($colors, "white", "default");
            }
        }
    }

    &__bookmark {
        padding: vw-calc(10) vw-calc(10) vw-calc(10) 0;

        @include media-breakpoint-up(md) {
            padding: vw-calc(20) vw-calc(10) vw-calc(20) 0;
        }

        svg {
            height: .75em;
            width: .6em;
            margin-right: .25em;
            vertical-align: baseline;
            fill: map-deep-get($colors, "white", "default");
            opacity: .6;
        }

        svg path {
            transition: $transition-default;
        }

        &--active svg{
            color: map-deep-get($colors, "brand", "default");
            opacity: 1;
        }
    }

    &__text {
        flex: 1;
        padding: vw-calc(10) 0;

        @include media-breakpoint-up(md) {
            padding: vw-calc(20) 0;
        }
    }

    &__open{
        display: inline-block;
        font-size: rem-calc(14);
        font-weight: 400;
        letter-spacing: 2.8px;
        text-transform: uppercase;
        padding: vw-calc(10) 0;

        @include media-breakpoint-up(md) {
            padding: vw-calc(20) 0;
        }

        svg {
            display: inline-block;
            width: vw-calc(30);
            height: vw-calc(20);
            vertical-align: middle;
        }

        svg path {
            fill: map-deep-get($colors, "brand", "default");
        }
    }

    &__open-text {
        transition: $transition-default;
        width: 0;
        height: 1.2em;
        overflow: hidden;
        display: inline-block;
    }

    &__content {
        position: relative;
        display: block;

        &--structured {
            padding-left: vw-calc(36);
        }
    }

    &__arrow {
        display: flex;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        align-items: center;
        opacity: 0.3;
        transition: $transition-default;

        &:hover {
            opacity: 1;
        }

        &--active {
            transform: rotate(90deg);
            opacity: 1;
        }

        svg {
            width: vw-calc(27);
            height: vw-calc(27);
        }
    }

    &__seperator {
        display: inline-block;
        margin: 0 vw-calc(6);
        width: 0;
        height: 0;
        border-top: vw-calc(6) solid transparent;
        border-left: vw-calc(10) solid map-deep-get($colors, "brand", "primary");
        border-bottom: vw-calc(6) solid transparent;
    }

    &--active {
        > #{$elm}__content #{$elm}__arrow{
            opacity: 1;
            transform: rotate(90deg);
        }

        > .navigation-section-index__list--sub {
            display: block;
        }
    }
}