.navigation-section-index {
    padding-top: vw-calc(20);
    height: 100%;

    @include media-breakpoint-up(md) {
        padding-top: vw-calc(50);
    }

    &__title {
        margin-top: vw-calc(18);
    }

    &__buttons {
        margin-bottom: vw-calc(18);

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            margin-top: vw-calc(18);
        }
    }

    &__search {
        position: relative;

        @include media-breakpoint-up(md) {
            margin-top: vw-calc(18);
        }
    }

    &__search-input {
        height: vw-calc(40);
        font-size: rem-calc(14);
        border: solid 1px map-deep-get($colors, "gray", "default");
    }

    &__search-submit {
        position: absolute;
        top: 0;
        right: rem-calc(8);
        display: flex;
        bottom: 0;
        align-items: center;
        padding: 0;
        background: none;
        border: none;

        svg {
            height: rem-calc(16);
            width: rem-calc(16);
        }
    }

    &__list {
        margin-top: vw-calc(50);
        overflow: hidden;
        display: none;

        &--sub {
            padding-left: vw-calc(50);
            margin-top: 0;
            height: auto;
            display: none;

            @include media-breakpoint-up(md) {
                padding-left: vw-calc(50);
            }
        }

        &--chapter {
            margin-top: 0;

            @include media-breakpoint-up(md) {
                margin-left: vw-calc(-36);
            }
        }

        &--menu-chapter {
            @include media-breakpoint-up(md) {
                padding-left: vw-calc(75);
            }
        }

        &--bookmarks {
            margin-top: 0;
        }

        &--active {
            display: block;
        }

        &:hover {
            overflow: auto;
        }
    }

    &__list-title {
        margin: 0 0 rem-calc(20) rem-calc(-40);
    }
}