.dashboard-sidebar {
    padding: vw-calc(40);
    overflow-y: auto;
    background: map-deep-get($colors, "gray", "light");
    $dashboard-sidebar: &;

    @include media-breakpoint-up(md) {
        position: fixed;
        left: 0;
        top: 0;
        width: 25%;
        min-height: 100vh;
        height: 100%;
    }

    &__container {
        position: relative;
        //height: 100%;
    }

    &__logo {
        margin-bottom: vw-calc(65);
        max-width: 80%;

        svg {
            width: 100%;
            height: auto;
        }
    }

    &__image {
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        margin-bottom: vw-calc(20);
        width: 70%;
        padding-top: 70%;
        background-position: center;
        background-size: cover;
        cursor: pointer;

        &:hover {
            #{$dashboard-sidebar}__image-upload-button {
                opacity: 1;
            }
        }
    }

    &__image-upload-button {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: vw-calc(10) 0;
        color: map-deep-get($colors, "white", "default");
        text-align: center;
        background: rgba(0, 0, 0, .3);
        opacity: 0;
        transition: $transition-default;
    }

    &__title {
        margin-bottom: vw-calc(10);
    }

    &__caption {
        display: inline-block;
        margin-bottom: vw-calc(45);
        color: map-deep-get($colors, "brand", "primary");
    }

    &__presentation-image {
        position: relative;
        width: 100%;
        max-width: 100%;
        margin: 0 0 vw-calc(45);
        padding: 70% 0 0;
        background-color: map-deep-get($colors, "gray", "default");
        background-size: cover;
    }

    &__button {
        margin-bottom: vw-calc(15);
    }

    &__list {
        margin-bottom: 2em;

        /* stylelint-disable */
        a {
            color: map-deep-get($colors, "black", "default");
        }
        /* stylelint-enable */
    }

    &__listitem {
        padding: .5em 0;

        svg {
            vertical-align: bottom;
            width: vw-calc(20);
            height: vw-calc(20);
        }
    }
}