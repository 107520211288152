.slide-list {
    $slide-list: &;

    &--light {
        #{$slide-list} {
            &__item {
                background: map-deep-get($colors, "white", "default");
            }

            &__input-wrapper::before {
                border: 2px solid map-deep-get($colors, "gray", "light");
            }

            &__input-dot {
                background-color: map-deep-get($colors, "brand", "primary");
            }
        }
    }

    &__input {
        display: none;

        &:checked {
            + #{$slide-list}__item {
                border-color: map-deep-get($colors, "brand", "primary");
            }

            + #{$slide-list}__item #{$slide-list}__input-dot {
                opacity: 1;
            }
        }
    }

    &__item {
        padding: 20px;
        margin-bottom: 7px;
        display: block;
        border: 2px solid map-deep-get($colors, "white", "default");
        cursor: pointer;
    }

    &__radio-wrapper {
        margin-bottom: 0;
    }

    &__label {
        margin-bottom: 0;
        display: inline-block;
        vertical-align: middle;
    }
}