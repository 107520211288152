.file-input {
    &__select-button {
        margin: 0 vw-calc(20) vw-calc(10) 0;
    }

    &__image-wrapper {
        display: inline-block;
        margin: 0 vw-calc(20) vw-calc(10) 0;
        width: vw-calc(80);
        height: vw-calc(60);
        line-height: vw-calc(60);
        background: map-deep-get($colors, "white", "default");
    }

    &__image {
        vertical-align: middle;
        max-width: 100%;
        max-height: 100%;
    }

    &__selected-file-name {
        display: inline-block;
        vertical-align: middle;
        margin: 0 vw-calc(20) vw-calc(10) 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: vw-calc(200);
    }

    &__delete-button {
        display: inline-block;
        width: vw-calc(36);
        height: vw-calc(36);
        padding: vw-calc(10);
        transition: $transition-default;
        background: map-deep-get($colors, "brand", "primary");
        border: 0;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            background: map-deep-get($colors, "brand", "light");
        }

        svg {
            width: vw-calc(16);
            height: vw-calc(16);
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    /* stylelint-disable */
    input[type="file"] {
        display: none;
    }
    /* stylelint-enable */
}