.videoslide {
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    padding-top: vw-calc(80);
    padding-bottom: vw-calc(80);
    transition: $transition-default;
    text-align: center;
    background: map-deep-get($colors, "black", "default");
    $videoslide: &;

    &__container {
        padding-top: 56.6%;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    &__wrapper {
        margin: 0 auto;
        position: relative;
        display: inline-block;
        height: 100%;
        padding: 0 20.4%;
    }

    &__filler-image {
        display: block;
        height: 100%;
        width: auto;
    }

    &__video-element {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &--focus {
        padding-top: vw-calc(0);
        padding-bottom: vw-calc(0);
    }
}
