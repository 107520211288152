.header-slide-person-modal {
    &__image {
        margin: 0 auto vw-calc(30);
        height: vw-calc(50);
        width: vw-calc(50);
        background-color: map-deep-get($colors, "gray", "default");
        background-position: center;
        background-size: cover;
        border-radius: 50%;

        @include media-breakpoint-up(md) {
            height: vw-calc(250);
            width: vw-calc(250);
        }
    }

    &__caption {
        margin-bottom: rem-calc(10);
        color: map-deep-get($colors, "brand", "primary");
    }

    &__name {
        margin-bottom: 1em;
    }

    &__listitem {
        margin-bottom: .5em;

        svg {
            vertical-align: bottom;
            width: vw-calc(20);
            height: vw-calc(20);
        }
    }
}