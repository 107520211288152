.header-slide-document {
    margin-left: vw-calc(20);
    background: map-deep-get($colors, "gray", "light");

    &__link {
        display: flex;
        height: 46px;
        align-items: center;
        padding: vw-calc(5) vw-calc(10);
        font-weight: 700;
        color: map-deep-get($colors, "brand", "default");
    }

    &__icon {
        padding-right: vw-calc(10);

        svg path {
            fill: map-deep-get($colors, "gray", "darker");
        }
    }
}
