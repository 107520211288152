.navigation-sections-list-item {
    background-position: center center;
    background-size: cover;
    width: 100%;
    margin-bottom: vw-calc(20);
    border: 2px solid map-deep-get($colors, "gray", "light");
    $elm: &;

    &--active {
        border: 2px solid map-deep-get($colors, "brand", "primary");
    }

    &__content {
        transition: $transition-default;
        padding: vw-calc(10) vw-calc(40) vw-calc(10) vw-calc(20);
        box-shadow: inset 0 -10vw 10vw -5vw rgba(map-deep-get($colors, "black", "default"), .6);
        display: flex;
        flex-wrap: wrap;
        min-height: vw-calc(80);

        @include media-breakpoint-up(md) {
            min-height: vw-calc(160);
            padding: vw-calc(20) vw-calc(40) vw-calc(20) vw-calc(20);
        }
    }

    &__title {
        line-height: 1.15em;
        color: map-deep-get($colors, "white", "default");
        margin-bottom: 0.5em;
        width: 100%;
        align-self: flex-start;
    }

    &__bookmark {
        svg{
            height: 0.75em;
            width: 0.6em;
            margin-right: .25em;
            vertical-align: baseline;
            fill: map-deep-get($colors, "white", "default");
            opacity: 0.6;
        }

        &--active svg{
            fill: map-deep-get($colors, "brand", "default");
            opacity: 1;
        }
    }

    &__buttons {
        align-self: flex-end;
        opacity: 0;
        transition: $transition-default;
        width: 100%;
    }

    &__button {
        margin-right: .5em;
        display: none;

        @include media-breakpoint-up(md) {
            display: inline-block;
        }
    }

    &:hover {
        #{$elm}__buttons {
            opacity: 1;
        }

        #{$elm}__content {
            background: rgba(map-deep-get($colors, "brand", "primary"), .5);
        }
    }
}