.officesslide {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin: 0 10%;
    padding: vw-calc(80) 0;
    position: relative;

    &__container {
        background-color: map-deep-get($colors, "gray", "light");
        padding: vw-calc(20) vw-calc(10);

        @include media-breakpoint-up(md) {
            padding: vw-calc(100) vw-calc(120) vw-calc(200);
        }
    }

    &__title {
        margin-bottom: vw-calc(20);
    }

    &__caption {
        display: inline-block;
        margin-bottom: vw-calc(40);
        color: map-deep-get($colors, "brand", "primary");
    }

    &__listmap {
        position: relative;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: rem-calc(40) rem-calc(-10) 0;
        position: relative;
        z-index: 1;
        justify-content: center;
        pointer-events: none;

        @include media-breakpoint-up(xl) {
            width: 65%;
            justify-content: left;
            margin: 0 rem-calc(-10);
        }

        @include media-breakpoint-up(xxl) {
            width: 61%;
        }
    }

    &__list-item {
        display: block;
        width: 100%;
        padding: 0 vw-calc(10) vw-calc(20);
        pointer-events: auto;

        @include media-breakpoint-up(md) {
            width: 50%;
        }

        @include media-breakpoint-up(xl) {
            width: 33.333%;
        }
    }

    &__map {
        position: relative;
        margin: 0 auto;
        display: inline-flex;

        @include media-breakpoint-up(xl) {
            top: vw-calc(-10);
            right: vw-calc(-100);
            position: absolute;
        }

        @include media-breakpoint-up(xxl) {
            right: rem-calc(-50);
        }
    }

    &__inner-map-img {
        width: 33vw;
        max-width: rem-calc(670);
    }

    &__markers {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &__marker {
        position: absolute;
        cursor: pointer;
        transform: translateX(-50%);
        opacity: 0;
        top: 0;
        transition: opacity $transition-default-time $transition-default-smoothing, top $transition-default-time $transition-cubic-smoothing;

        svg {
            width: vw-calc(15);
            transition: $transition-default;

            @include media-breakpoint-up(md) {
                width: vw-calc(20);
            }
        }

        &:hover,
        &:focus,
        &--active {
            svg {
                fill: map-deep-get($colors, "brand", "primary");
            }
        }
    }
}

.office-item {
    background-color: map-deep-get($colors, "white", "default");
    transition: $transition-default;
    display: block;
    color: map-deep-get($colors, "black", "default");
    padding: 16px 24px;
    $office-item: &;

    &__more {
        margin-top: rem-calc(10);
        display: flex;
        align-items: center;
        justify-content: left;

        svg {
            width: 32px;
            margin-right: 8px;
            fill: map-deep-get($colors, "brand", "primary");
            transition: $transition-default;
        }
    }

    &__title {
        font-size: rem-calc(20);
    }

    &:hover,
    &:focus,
    &--active {
        background-color: map-deep-get($colors, "brand", "primary");
        color: map-deep-get($colors, "white", "default");

        #{$office-item}__more {
            svg {
                fill: map-deep-get($colors, "white", "default");
            }
        }
    }
}
