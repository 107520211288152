.personslide {
    margin: 0 10%;
    padding: vw-calc(80) 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__container {
        padding: vw-calc(170) 0;
        background: map-deep-get($colors, "gray", "light");
    }

    &__column {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__image {
        width: 100%;
        padding-top: 100%;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__name {
        margin-bottom: vw-calc(20);
    }

    &__function,
    &__phone,
    &__mail {
        display: block;
        margin-bottom: vw-calc(10);

        svg {
            vertical-align: bottom;
            width: vw-calc(30);
        }
    }
}