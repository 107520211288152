$hamburger-padding-x                       : .5625rem;
$hamburger-padding-y                       : .5625rem;
$hamburger-layer-width                     : 1.375rem;
$hamburger-layer-height                    : .2rem;
$hamburger-layer-spacing                   : .2rem;
$hamburger-layer-color                     : map-deep-get($colors, "brand", "primary");
$hamburger-layer-border-radius             : 0;
$hamburger-border-radius                   : 50%;
$hamburger-hover-opacity                   : 1;
$hamburger-hover-transition-duration       : .45s;
$hamburger-hover-transition-timing-function: linear;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
//

$hamburger-hover-use-filter: false;
$hamburger-hover-filter    : opacity(50%);
$hamburger-types: (squeeze);

@import "hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
    z-index: 11;
    border-radius: $hamburger-border-radius;

    &-box {
        height: $hamburger-layer-width;
        display: flex;
    }

    &-inner {
        margin-top: -1.75px;
    }

    &--inverted {
        .hamburger-inner {
            background-color: map-deep-get($colors, "black", "default");

            &::after, &::before {
                background-color: map-deep-get($colors, "black", "default");
            }
        }
    }

    &.is-active {
        background-color: map-deep-get($colors, "brand", "primary");

        .hamburger-inner {
            background: map-deep-get($colors, "white", "default");

            &::after,
            &::before {
                background: inherit;
            }
        }
    }
}

.hamburger-box {
    height: #{$hamburger-layer-height * 3} + #{$hamburger-layer-spacing * 2};
}