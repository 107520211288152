.modal {
    position: fixed;
    z-index: 120;
    top: -100vh;
    left: 0;
    padding: vw-calc(30) 10vw;
    width: 100vw;
    height: 100vh;
    text-align: center;
    opacity: 0;
    background: map-deep-get($colors, "gray", "light");
    border: solid map-deep-get($colors, "white", "default");
    border-width: vw-calc(20) vw-calc(30) vw-calc(20) vw-calc(30);
    transition: $transition-default;

    @include media-breakpoint-up(md) {
        border-width: vw-calc(50) 10vw vw-calc(50) 10vw;
    }

    &--visible {
        top: 0;
        opacity: 1;
    }

    &__close {
        display: inline-block;
        margin-bottom: vw-calc(10);
        width: vw-calc(36);
        height: vw-calc(36);
        padding: vw-calc(10);
        transition: $transition-default;
        background: map-deep-get($colors, "brand", "primary");
        border: 0;
        border-radius: 50%;
        cursor: pointer;

        @include media-breakpoint-up(md) {
            margin-bottom: vw-calc(30);
        }

        &:hover {
            background: map-deep-get($colors, "brand", "light");
        }

        svg {
            width: vw-calc(16);
            height: vw-calc(16);
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }
}