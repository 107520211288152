.header-bookmark {
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    height: vw-calc(40);
    justify-content: center;
    width: vw-calc(40);
    $bookmark: &;

    &--this {
        box-shadow: 0 0 vw-calc(1) 0 map-deep-get($colors, "gray", "default");
        background-color: map-deep-get($colors, "white", "default");
        position: relative;
        z-index: 1;
    }

    &--overview {
        background: map-deep-get($colors, "brand", "primary");
        color: map-deep-get($colors, "white", "default");
        margin-left: -#{vw-calc(5)};
    }

    &:hover {
        #{$bookmark}-icon--default {
            svg {
                fill: map-deep-get($colors, "brand", "primary");
                opacity: 1;
            }
        }
    }

    &--active {
        #{$bookmark}-icon svg {
            fill: map-deep-get($colors, "brand", "primary");
            opacity: 1;
        }
    }
}