.quoteslide {
    display: flex;
    flex-flow: column;
    justify-content: center;
    min-height: 100vh;
    height: 100vh;
    margin: 0 10%;
    padding: vw-calc(80) 0;

    &__caption {
        display: inline-block;
        margin-top: vw-calc(20);
        color: map-deep-get($colors, "brand", "primary");
    }

    &__title {
        &--small {
            font-size: rem-calc(24);
            line-height: rem-calc(30);
        }

        &--normal {
            font-size: rem-calc(36);
            line-height: rem-calc(50);
        }
    }
}