.navigation-sections {
    background: map-deep-get($colors, "gray", "light");
    padding: vw-calc(20) 0;
    height: 100%;

    @include media-breakpoint-up(md) {
        padding: vw-calc(50) 0;
    }

    &__title {
        margin: .25em 0;
        padding-left: vw-calc(50);
        line-height: 1em;

        @include media-breakpoint-up(md) {
            padding-left: 0;
            margin: 0 0 .5em;
        }
    }

    &__list {
        margin-top: vw-calc(10);
        overflow: auto;
        height: calc(100vh - #{vw-calc(200)});
        padding-right: vw-calc(20);

        &--chapters {
            height: calc(100vh - #{vw-calc(100)});
        }

        &--slides {
            height: calc(100vh - #{vw-calc(100)});
        }

        &--bookmarks {
            height: calc(100vh - #{vw-calc(220)});
        }

        &--person {
            margin-top: vw-calc(0);
            padding-right: 0;
            height: calc(100vh - #{vw-calc(140)});
        }

        @include media-breakpoint-up(md) {
            margin-top: vw-calc(50);

            &--chapters {
                height: calc(100vh - #{vw-calc(260)});
            }

            &--slides {
                height: calc(100vh - #{vw-calc(200)});
            }
        }
    }
}