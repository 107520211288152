.presentation-card {
    margin-bottom: vw-calc(20);
    background: map-deep-get($colors, "gray", "light");

    &__image {
        position: relative;
        width: 100%;
        max-width: 100%;
        height: 10em;
        margin: 0;
        padding: 0;
        background-color: map-deep-get($colors, "gray", "default");
        background-size: cover;

        @include media-breakpoint-up(lg) {
            position: absolute;
            width: calc(100vw - #{vw-calc(20)});
            height: 100%;
        }
    }

    &__delete {
        position: absolute;
        z-index: 2;
        top: vw-calc(10);
        left: vw-calc(10);
        width: vw-calc(36);
        height: vw-calc(36);
        padding: vw-calc(10);
        transition: $transition-default;
        background: map-deep-get($colors, "brand", "primary");
        border: 0;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            background: map-deep-get($colors, "brand", "light");
        }

        svg {
            width: vw-calc(16);
            height: vw-calc(16);
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__content {
        padding: vw-calc(20);
        position: relative;
        height: 100%;

        @include media-breakpoint-up(lg) {
            padding: vw-calc(32) vw-calc(64);
        }
    }

    &__icon {
        position: absolute;
        transform: translate(-50%, -50%);

        @include media-breakpoint-down(lg) {
            top: 0;
            left: 50%;
        }

        @include media-breakpoint-up(lg) {
            top: 50%;
            left: 0;
        }

        &::before {
            content: "";
            display: block;
            width: vw-calc(28);
            height: vw-calc(28);
            transform-origin: center;
            transform: rotate(-45deg);
            background-color: map-deep-get($colors, "white", "default");
        }

        &::after {
            content: "";
            position: absolute;
            inset: 2px;
            background: url('data:image/svg+xml,<svg width="40" height="37" viewBox="0 0 40 37" xmlns="http://www.w3.org/2000/svg"><path d="M2.63419 1.66463C0.114187 3.11463 -0.745813 6.34463 0.704187 8.85463C1.61419 10.4246 3.31419 11.4846 5.26419 11.4846H14.3842L9.82419 3.58463C8.37419 1.06463 5.14419 0.204627 2.63419 1.65463V1.66463Z" fill="%23AE3255"/><path d="M33.9142 0.9646H5.3042C7.1142 0.9746 8.8642 1.9246 9.8342 3.5946L14.3942 11.4946H24.7942L29.3542 3.5946C30.3242 1.9046 32.0942 0.9646 33.9142 0.9646Z" fill="%23EF174D"/><path d="M33.9142 0.964346C32.0942 0.964346 30.3242 1.91435 29.3542 3.59435L24.7942 11.4943H33.9142C36.8242 11.4943 39.1842 9.13435 39.1842 6.22435C39.1842 3.31435 36.8242 0.954346 33.9142 0.954346V0.964346Z" fill="%23008F8E"/><path d="M16.9641 35.5944C19.4841 37.0444 22.7041 36.1844 24.1541 33.6644C25.0641 32.0844 25.1241 30.0844 24.1541 28.3944L19.5941 20.4944L15.0341 28.3944C13.5841 30.9144 14.4441 34.1344 16.9641 35.5844V35.5944Z" fill="%23156F7E"/><path d="M0.714111 8.8645L15.0241 33.6445C14.1341 32.0745 14.0741 30.0845 15.0441 28.4045L19.6041 20.5045L14.4041 11.4945H5.28411C3.33411 11.4945 1.63411 10.4345 0.724112 8.8645H0.714111Z" fill="%2328899F"/><path d="M24.1541 33.6645L38.4641 8.88452C37.5541 10.4445 35.8541 11.4845 33.9241 11.4845H24.8041L19.6041 20.4945L24.1641 28.3945C25.1341 30.0845 25.0741 32.0845 24.1641 33.6645H24.1541Z" fill="%2300B49C"/></svg>') no-repeat center;
            background-size: 60%;
        }
    }

    &__caption {
        color: map-deep-get($colors, "brand", "primary");
    }

    &__title {
        margin-bottom: 1em;
    }

    &__chapters {
        margin-bottom: 1em;
    }
}
