.pie-chart-legend {
    margin-top: 2em;

    &__item {
        margin-bottom: .5em;
        display: flex;
    }

    &__color {
        display: inline-block;
        height: 1em;
        width: 1em;
        flex: 0 0 1em;
        margin-right: 1em;
    }

    &__label {
        max-height: 1.5em;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}