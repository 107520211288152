.browse {
    &__btn {
        position: fixed;
        z-index: 14;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-content: center;
        width: vw-calc(40);
        height: vw-calc(40);
        border-radius: 50%;
        opacity: .2;
        transition: $transition-default;
        background: map-deep-get($colors, "brand", "primary");

        svg {
            width: vw-calc(25);
            height: vw-calc(25);
            margin: vw-calc(7.5);
            transition: $transition-default;
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }

        &:hover {
            opacity: 1;
            width: vw-calc(50);
            height: vw-calc(50);

            svg {
                width: vw-calc(30);
                height: vw-calc(30);
                margin: vw-calc(10);
            }
        }

        @include media-breakpoint-up(md) {
            width: vw-calc(80);
            height: vw-calc(80);

            svg {
                width: vw-calc(50);
                height: vw-calc(50);
                margin: vw-calc(16);
            }

            &:hover {
                width: vw-calc(90);
                height: vw-calc(90);

                svg {
                    width: vw-calc(60);
                    height: vw-calc(60);
                    margin: vw-calc(16);
                }
            }
        }

        &--left {
            left: vw-calc(10);

            &:hover {
                left: vw-calc(5);
            }
        }

        &--right {
            right: vw-calc(10);

            &:hover {
                right: vw-calc(5);
            }
        }

        &--left-hidden {
            left: vw-calc(-100);
        }

        &--right-hidden {
            right: vw-calc(-100);
        }
    }
}