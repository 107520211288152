﻿h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

h1, .h1 {
    font-family: $font-special;
    font-size: rem-calc(32);
    font-weight: 500;
    line-height: 1.1;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(50);
    }

    &--huge {
        font-size: rem-calc(36);

        @include media-breakpoint-up(md) {
            font-size: rem-calc(90);
        }
    }
}

h2, .h2 {
    font-family: $font-special;
    font-size: rem-calc(28);
    font-weight: 500;
    line-height: 1.1;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(32);
    }
}

h3, .h3 {
    font-family: $font-special;
    font-size: rem-calc(21);
    font-weight: 500;
    line-height: 1.4;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(28);
    }
}

h4, .h4 {
    font-family: $font-special;
    font-size: rem-calc(18);
    font-weight: 500;
    line-height: 1.1;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(24);
    }
}

h5, .h5 {
    font-family: $font-special;
    font-size: rem-calc(16);
    font-weight: 700;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(20);
    }
}

h6, .h6 {
    font-family: $font-special;
    font-size: rem-calc(14);
}

.caption {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    font-size: rem-calc(10);

    @include media-breakpoint-up(md) {
        font-size: rem-calc(14);
        letter-spacing: 2.8px;
    }
}
