﻿/// <reference path="../_reference.scss" />

*, *::before, *::after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

html {
    font-size: rem-calc(16);

    @include media-breakpoint-up(xxl) {
        font-size: (100 / 1600) * 16vw;
    }
}

html, body {
    color: map-get($new-colors, "quaternary");
    margin: 0 auto;
    padding: 0;
    font-family: $font-default;
}

[v-cloak] {
    display: none;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

a {
    text-decoration: none;
    color: #e50044;
}

b, strong {
    font-weight: 700;
}

.h-100 {
    height: 100%;
}

::selection {
    background-color: map-deep-get($colors, "brand", "primary");
    color: map-deep-get($colors, "white", "default");
}

@function rem-calc($size) {
    $remSize: $size / 16;
    @return #{$remSize}rem;
}

@function vw-calc($size) {
    $vwSize: (100 / 1600) * $size;
    @return #{$vwSize}rem;
}
