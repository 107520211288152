.navigation {
    background: map-deep-get($colors, "white", "default");
    display: flex;
    height: 100vh;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: -100vh;
    opacity: 0;
    transition: $transition-default;
    width: 100%;
    z-index: 100;

    &--active {
        opacity: 1;
        top: 0;
    }

    &__container {
        width: 100%;
    }
}