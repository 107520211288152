﻿//
// Breakpoints
// Make sure these are the same as foundation's breakpoints!
$breakpoints: ("small": 0, "medium": 640px, "large": 1024px, "xlarge": 1200px, "xxlarge": 1440px,);

//
// Colors
$new-colors: (
    "primary": #ec1e50,
    "secondary": #24748a,
    "tertiary": #179986,
    "quaternary": #000d4d,
    "purple": #471738,
    "azure": #0c6268,
    "light": #f2f8fa
);

$colors: (
    "white": ("default": #ffffff),
    "black": ("default": #000000),
    "gray": ("default": #cccccc, "light": map-get($new-colors, "light"), "dark": #d8d8d8, "darker": #7f7f7f),
    "brand": ("primary": map-get($new-colors, "primary"), "dark": darken(map-get($new-colors, "primary"), 25%), "darker": darken(map-get($new-colors, "primary"), 40%), "default": map-get($new-colors, "primary"), "light": lighten(map-get($new-colors, "primary"), 25%), "lighter": lighten(map-get($new-colors, "primary"), 50%)),
    "validation": ("valid": #55b890, "invalid": #e50044, "valid-dark": darken(#55b890, 25%), "invalid-dark": darken(#e50044, 25%)),
    "social": ("facebook": #3b5998, "twitter": #1da1f2, "instagram": #c13584, "youtube": #ff0000, "linkedin": #0077b5),
);

//
// Spacing
$spacing: (
    "default": (1: 8px, 2: 16px, 3: 24px, 4: 32px, 5: 40px, 6: 48px, 7: 56px, 8: 64px, 9: 72px, 10: 80px),
    "small": (1: 4px, 2: 8px, 3: 12px, 4: 16px, 5: 20px, 6: 24px, 7: 28px, 8: 32px, 9: 36px, 10: 40px)
);

//
// Fonts
@font-face {
    font-family: ibm-plex-sans;
    src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("/fonts/IBMPlexSans-BoldItalic.woff2") format("woff2");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: ibm-plex-sans;
    src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("/fonts/IBMPlexSans-Thin.woff2") format("woff2");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: ibm-plex-sans;
    src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("/fonts/IBMPlexSans-SemiBoldItalic.woff2") format("woff2");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: ibm-plex-sans;
    src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("/fonts/IBMPlexSans-Bold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: ibm-plex-sans;
    src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("/fonts/IBMPlexSans-ThinItalic.woff2") format("woff2");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: ibm-plex-sans;
    src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("/fonts/IBMPlexSans-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: ibm-plex-sans;
    src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("/fonts/IBMPlexSans-Italic.woff2") format("woff2");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: ibm-plex-sans;
    src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("/fonts/IBMPlexSans-MediumItalic.woff2") format("woff2");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: ibm-plex-sans;
    src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("/fonts/IBMPlexSans-ExtraLightItalic.woff2") format("woff2");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: ibm-plex-sans;
    src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("/fonts/IBMPlexSans-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: ibm-plex-sans;
    src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("/fonts/IBMPlexSans-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: ibm-plex-sans;
    src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("/fonts/IBMPlexSans-LightItalic.woff2") format("woff2");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: ibm-plex-sans;
    src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("/fonts/IBMPlexSans-ExtraLight.woff2") format("woff2");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: ibm-plex-sans;
    src: local("IBM Plex Sans Regular"), local("IBMPlexSans-Regular"), url("/fonts/IBMPlexSans-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: blacker-pro-display;
    src: local("Blacker Pro Display Light"), local("BlackerProDisplay-Light"), url("/fonts/BlackerProDisplay-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: blacker-pro-display;
    src: local("Blacker Pro Display Light Italic"), local("BlackerProDisplay-LightItalic"), url("/fonts/BlackerProDisplay-LightItalic.woff2") format("woff2");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: blacker-pro-display;
    src: local("Blacker Pro Display Regular"), local("BlackerProDisplay-Regular"), url("/fonts/BlackerProDisplay-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: blacker-pro-display;
    src: local("Blacker Pro Display Italic"), local("BlackerProDisplay-Italic"), url("/fonts/BlackerProDisplay-Italic.woff2") format("woff2");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: blacker-pro-display;
    src: local("Blacker Pro Display Medium"), local("BlackerProDisplay-Medium"), url("/fonts/BlackerProDisplay-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: blacker-pro-display;
    src: local("Blacker Pro Display Medium Italic"), local("BlackerProDisplay-MediumItalic"), url("/fonts/BlackerProDisplay-MediumItalic.woff2") format("woff2");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: blacker-pro-display;
    src: local("Blacker Pro Display Bold"), local("BlackerProDisplay-Bold"), url("/fonts/BlackerProDisplay-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: blacker-pro-display;
    src: local("Blacker Pro Display Bold Italic"), local("BlackerProDisplay-BoldItalic"), url("/fonts/BlackerProDisplay-BoldItalic.woff2") format("woff2");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: blacker-pro-display;
    src: local("Blacker Pro Display Extrabold"), local("BlackerProDisplay-Extrabold"), url("/fonts/BlackerProDisplay-Extrabold.woff2") format("woff2");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: blacker-pro-display;
    src: local("Blacker Pro Display Extrabold Italic"), local("BlackerProDisplay-ExtraboldItalic"), url("/fonts/BlackerProDisplay-ExtraboldItalic.woff2") format("woff2");
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: blacker-pro-display;
    src: local("Blacker Pro Display Heavy"), local("BlackerProDisplay-Heavy"), url("/fonts/BlackerProDisplay-Heavy.woff2") format("woff2");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: blacker-pro-display;
    src: local("Blacker Pro Display Heavy Italic"), local("BlackerProDisplay-HeavyItalic"), url("/fonts/BlackerProDisplay-HeavyItalic.woff2") format("woff2");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

$font-default: ibm-plex-sans, sans-serif;
$font-special: blacker-pro-display, sans-serif;

//
// Transition defaults
$transition-default-smoothing: ease-in-out;
$transition-default-time: .3s;
$transition-default: all $transition-default-time $transition-default-smoothing;

$transition-cubic-smoothing: cubic-bezier(.2, 0, 0, 1);
$transition-cubic-time: .2s;
$transition-cubic: all $transition-cubic-time $transition-cubic-smoothing;

//
// Border Radius
$border-radius: 4px;
