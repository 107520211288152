.login-form {
    background: map-deep-get($colors, "gray", "light");
    margin: vw-calc(30) 0;
    padding: vw-calc(32);
    color: map-get($new-colors, "quaternary");
    font-size: rem-calc(14);

    @include media-breakpoint-up(md) {
        padding: vw-calc(32) vw-calc(64);
    }

    &__submit-button {
        margin-right: vw-calc(20);
    }

    &__link {
        display: inline-block;
        padding: vw-calc(8) 0;
        line-height: rem-calc(22);
        color: map-deep-get($colors, "brand", "primary");
    }
}
