.form-block {
    padding: vw-calc(20) vw-calc(20) vw-calc(60);
    margin-bottom: vw-calc(20);
    background: map-deep-get($colors, "gray", "light");

    @include media-breakpoint-up(md) {
        padding: vw-calc(20) vw-calc(60) vw-calc(60);
    }

    &__caption {
        display: inline-block;
        margin-bottom: rem-calc(40);
        color: map-deep-get($colors, "brand", "primary");

        /* stylelint-disable */
        &.link-title {
            margin-top: rem-calc(25);
        }
        /* stylelint-enable */
    }
}
