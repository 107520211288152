input, select {
    border-radius: $border-radius;
    height: rem-calc(40);
    border: 1px solid  map-deep-get($colors, "white", "default");
    padding: 0 rem-calc(16);
    width: 100%;
    transition: $transition-default;
    font-family: $font-default;
    font-size: rem-calc(16);

    &:focus {
        outline: none;
        border: 1px solid map-deep-get($colors, "brand", "primary");
    }
}

/* basic form styling does not fit stylelint requirements */
/* stylelint-disable */
input[type='file'] {
    padding: 0;
    border: 0;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.btn {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
}

input[type='submit'] {
    width: auto;
}
/* stylelint-enable */

textarea {
    border-radius: $border-radius;
    border: 1px solid map-deep-get($colors, "white", "default");
    padding: rem-calc(8) rem-calc(16);
    resize: vertical;
    min-height: rem-calc(100);
    width: 100%;
    font-size: rem-calc(16);
    font-family: Asap, sans-serif;
    transition: $transition-default;

    &:focus {
        outline: none;
        border: 1px solid map-deep-get($colors, "brand", "primary");
    }
}

select {
    background: map-deep-get($colors, "white", "default");

    &::-ms-expand {
        display: none;
    }
}

.select-wrapper {
    position: relative;

    &__select {
        height: rem-calc(40);
        color: map-deep-get($colors, "brand", "primary");
        border: 1px solid map-deep-get($colors, "white", "default");
        letter-spacing: 2.8px;
        text-transform: uppercase;
        font-size: rem-calc(14);
        font-weight: 500;
        padding: 0 20px;
        border-radius: $border-radius;
        appearance: none;

        &:focus {
            border: 1px solid map-deep-get($colors, "brand", "primary");
        }
    }

    &__dropdown-icon {
        position: absolute;
        right: rem-calc(10);
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        pointer-events: none;
    }

    //Directly referencing option element because of select context.
    /* stylelint-disable */
    option {
        font-size: 18px;
        text-transform: none;
        padding: 12px 0;
    }
    /* stylelint-enable */
}

//Radio Button styling

.radio-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: rem-calc(8);
    $radioWrapper: &;

    &__input-wrapper {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        width: rem-calc(25);
        height: rem-calc(25);
        margin-right: rem-calc(8);
        cursor: pointer;

        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            border-radius: 50%;
            background-color: map-deep-get($colors, "white", "default");
            border: 1px solid map-deep-get($colors, "white", "default");
            display: block;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
        }
    }

    &__input-dot {
        position: absolute;
        left: rem-calc(6);
        top: rem-calc(6);
        z-index: 2;
        border-radius: 50%;
        background-color: map-deep-get($colors, "validation", "valid");
        width: rem-calc(13);
        height: rem-calc(13);
        opacity: 0;
    }

    // Default input styling erin gehangen
    /* stylelint-disable */
    &__input, input {
        line-height: 1;
        position: absolute;
        left: rem-calc(-999);

        &:checked {
            + #{$radioWrapper}__input-dot {
                opacity: 1;
            }
        }
    }

    &__label, label {
        font-size: rem-calc(15);
        cursor: pointer;
        margin-bottom: 0;
    }
    /* stylelint-enable */
}

.checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: rem-calc(8);
    $checkboxWrapper: &;

    // Default input styling erin gehangen
    /* stylelint-disable */
    &__input, input {
        line-height: 1;
        position: absolute;
        left: rem-calc(-999);

        &:checked {
            + #{$checkboxWrapper}__input-dot {
                opacity: 1;
            }
        }
    }

    &__label, label {
        font-size: rem-calc(15);
        cursor: pointer;
        font-weight: 400;
        margin-bottom: 0;
    }
    /* stylelint-enable */

    &__input-wrapper {
        position: relative;
        width: rem-calc(25);
        height: rem-calc(25);
        min-width: rem-calc(25);
        min-height: rem-calc(25);
        margin-right: rem-calc(8);
        cursor: pointer;

        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            border-radius: rem-calc(5);
            background-color: map-deep-get($colors, "white", "default");
            border: 1px solid map-deep-get($colors, "white", "default");
            display: block;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
        }
    }

    &__input-dot {
        position: absolute;
        left: rem-calc(5);
        right: 0;
        top: rem-calc(4);
        bottom: 0;
        z-index: 2;
        width: rem-calc(16);
        opacity: 0;

        svg {
            fill: map-deep-get($colors, "validation", "valid");
        }
    }
}

.input {
    &__addition {
        padding-left: 0.5em;
        padding-right: 0.5em;
        align-items: center;
        border-right: map-deep-get($colors, "gray", "light");
        background-color: map-deep-get($colors, "white", "default");
        min-width: rem-calc(40);
        text-align: center;
        min-height: 100%;
        display: inline-block;
    }

    &__addition + input {
        display: inline-block;
        flex-grow: 1;
    }
}

.input-wrapper {
    position: relative;

    &--addition{
        display: flex;
        background: map-deep-get($colors, "white", "default");
        align-items: center;
    }
}