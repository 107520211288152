.form {
    $form: &;

    &__group-wrapper {
        &--half {
            #{$form}__group:last-of-type {
                margin-bottom: rem-calc(24);
            }

            @include media-breakpoint-up(md) {
                display: flex;
                margin: 0 rem-calc(-8);

                #{$form}__group {
                    margin: 0 rem-calc(8) rem-calc(24);
                    width: 50%;
                }
            }
        }
    }

    &__group {
        margin-bottom: rem-calc(24);

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__invalid-message {
        color: map-deep-get($colors, "validation", "invalid");
    }

    &__label {
        font-weight: 700;
        display: block;
        margin-bottom: rem-calc(16);
    }

    /* stylelint-disable */
    &.form--loading{

        /* Button wordt gegenereerd */
        [type="submit"]{
            pointer-events: none;
            background-color: map-deep-get($colors, "brand", "primary");
        }
    }
    /* stylelint-enable */
}