.dashboard-heading {
    padding: vw-calc(50) 0;
    margin: 0 vw-calc(10);

    @include media-breakpoint-up(md) {
        margin: 0 5%;
    }

    &__title {
        margin: 0 0 1em;

        @include media-breakpoint-up(md) {
            margin: 0;
        }
    }

    &__button {
        float: none;

        @include media-breakpoint-up(md) {
            float: right;
        }
    }
}