.button {
    border-radius: $border-radius;
    padding: rem-calc(8) rem-calc(16);
    min-height: rem-calc(40);
    background-color: map-deep-get($colors, "brand", "primary");
    color: map-deep-get($colors, "white", "default");
    border: 1px solid transparent;
    font-size: rem-calc(14);
    transition: $transition-default;
    font-weight: 400;
    letter-spacing: 2.8px;
    text-transform: uppercase;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    line-height: vw-calc(22);

    &:hover,
    &:focus {
        background-color: map-deep-get($colors, "brand", "light");
    }

    &--block {
        width: 100%;
    }

    &--invert {
        background-color: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "brand", "default");

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "brand", "light");
            color: map-deep-get($colors, "white", "default");
        }
    }

    &--white {
        background: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "brand", "default");

        &:hover {
            background: map-deep-get($colors, "white", "default");
            color: map-deep-get($colors, "black", "default");
        }
    }
}

.round-button {
    background-color: map-deep-get($colors, "brand", "default");
    width: rem-calc(50);
    height: rem-calc(50);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $transition-default;

    @include media-breakpoint-up(xl) {
        width: rem-calc(80);
        height: rem-calc(80);
    }

    svg {
        width: rem-calc(26);
        height: auto;
        fill: map-deep-get($colors, "white", "default");
    }
}

.link {
    display: inline-block;
    padding: vw-calc(10) 0;
    color: map-deep-get($colors, "black", "default");

    &--white {
        color: map-deep-get($colors, "white", "default");
    }

    &__icon {
        display: inline-block;
        vertical-align: text-bottom;

        svg {
            width: 2em;
            height: 1em;
            vertical-align: middle;
        }

        &--red {
            svg path {
                fill: map-deep-get($colors, "brand", "default");
            }
        }
    }
}