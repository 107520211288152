.header-notes {
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    height: vw-calc(40);
    justify-content: center;
    width: vw-calc(40);
    $bookmark: &;

    &__button {
        position: relative;
        z-index: 1;
        padding: vw-calc(9);
        width: vw-calc(36);
        height: vw-calc(36);
        border-radius: 50%;
        background-color: map-deep-get($colors, "brand", "primary");
        color: #ffffff;
        text-align: center;

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__modal-body {
        padding: vw-calc(20) vw-calc(10) 0;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: left;
        height: calc(100vh - #{vw-calc(200)});
    }

    &__premade-notes {
        margin: vw-calc(20) 0 0;
        height: calc(100vh - #{vw-calc(335)});
        width: 100%;
        padding: 8px 16px;
        overflow-y: auto;
        border-radius: vw-calc(5);
        background: map-deep-get($colors, "white", "default");
    }

    &__input {
        margin: vw-calc(20) 0;
        height: calc(100vh - #{vw-calc(400)});
    }

    &--active {
        #{$bookmark}-icon svg {
            fill: map-deep-get($colors, "brand", "primary");
            opacity: 1;
        }
    }
}