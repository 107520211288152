.header-slide-person {
    margin-left: vw-calc(20);
    background: map-deep-get($colors, "gray", "light");

    &__link {
        display: flex;
        align-items: center;
        padding: vw-calc(5) vw-calc(10);
    }

    &__image {
        height: vw-calc(36);
        width: vw-calc(36);
        background-color: map-deep-get($colors, "gray", "default");
        background-position: center;
        background-size: cover;
        border-radius: 50%;
    }

    &__name {
        margin-left: vw-calc(10);
        font-weight: 700;
        color: map-deep-get($colors, "brand", "default");
    }
}