.hero {
    position: relative;
    overflow: hidden;
    z-index: 2;
    color: map-deep-get($colors, "white", "default");
    background-color: map-deep-get($colors, "black", "default");
    align-content: space-between;
    $hero: &;

    &__container {
        margin: 0 10%;
    }

    &__content-wrapper {
        width: 100%;
        height: auto;
        min-height: rem-calc(600);
        justify-content: center;
        flex-wrap: wrap;
        display: flex;
        align-content: start;
        margin-top: rem-calc(100);
        max-height: rem-calc(1000);

        @include media-breakpoint-up(xl) {
            min-height: rem-calc(800);
            height: 100vh;
        }
    }

    &::before {
        content: "";
        background: linear-gradient(90deg, rgba(map-deep-get($colors, "black", "default"), .7) 0%, rgba(map-deep-get($colors, "black", "default"), 0) 100%);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 3;
    }

    &::after {
        content: "";
        background-color: map-deep-get($colors, "white", "default");
        height: rem-calc(250);
        width: 120%;
        position: absolute;
        bottom: rem-calc(-150);
        left: -10%;
        z-index: 3;
        transform: rotate(-10deg);

        @include media-breakpoint-up(md) {
            height: rem-calc(250);
            bottom: rem-calc(-90);
        }
        @include media-breakpoint-up(xl) {
            height: rem-calc(350);
            bottom: rem-calc(-180);
        }
    }

    &__bg {
        background-size: cover;
        background-position: top center;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 2;

        @include media-breakpoint-up(xl) {
            background-position: center;
        }

        // Lazyloading opacity transformation. Vendor styling transitions opacity to 1.
        // This instance it needs to change to opacity: .2
        /* stylelint-disable */
        &.b-loaded {
            opacity: 1;
        }
        /* stylelint-enable */
    }

    &__main-content, &__sub-content {
        position: relative;
        z-index: 4;
        width: 100%;
    }

    &__main-content {
        margin-top: rem-calc(100);

        @include media-breakpoint-up(xl) {
            margin-top: rem-calc(150);
        }
    }

    &__title {
        margin: map-deep-get($spacing, "default", 1) 0 0 0;
        font-size: rem-calc(32);
    }

    &__text {
        margin: 0;
    }

    &__subtitle {
        font-weight: 500;
    }

    &__pattern {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 3;

        svg {
            float: right;
        }

        &--red {
            svg g {
                fill: map-deep-get($colors, "brand", "primary");
            }
        }
    }

    &__sub-content {
        margin-top: rem-calc(64);

        @include media-breakpoint-up(xl) {
            bottom: 28vh;
            position: absolute;
        }

        @include media-breakpoint-up(xl) {
            bottom: rem-calc(350);
        }

        svg {
            margin-right: 32px;
            height: 100px;
            width: 75px;
        }

        svg g {
            fill: map-deep-get($colors, "white", "default");
        }

        #{$hero}__inner-content {
            display: flex;
            color: map-deep-get($colors, "white", "default");
        }
    }

    &--small {
        #{$hero}__sub-content {
            @include media-breakpoint-up(xl) {
                bottom: rem-calc(150);
            }
        }
    }

    &--white {
        &::after {
            background-color: map-deep-get($colors, "white", "default");
        }
    }
}