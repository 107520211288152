.header-bookmark-icon {
    $bookmark: &;

    svg {
        transition: $transition-default;
        height: vw-calc(22);
        width: vw-calc(16);
        opacity: .3;
    }

    &--active {
        position: absolute;
        top: 0;
        left: 0;

        svg {
            opacity: 0;
        }
    }
}