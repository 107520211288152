$header-height: ("small": 50px, "medium": 80px);

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 15;
    margin: 0 auto;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding: rem-calc(20);
    transition: $transition-default;

    $header: &;

    &--menuExpanded {
        #{$header} {
            //styling
        }
    }

    /* stylelint-disable */
    &--inverted {
        .hamburger-inner {
            background: map-deep-get($colors, "white", "default");

            &::after,
            &::before {
                background: inherit;
            }
        }
    }
    /* stylelint-enable */

    &--hidden {
        top: vw-calc(-100);
    }

    &__left-part {
        display: inline-flex;
    }

    &__menu-toggle {
        align-items: center;
        display: flex;
        height: vw-calc(40);
        justify-content: center;
        width: vw-calc(40);
        z-index: 110;
        position: relative;
    }

    &__bookmarks {
        display: inline-flex;
        margin-left: vw-calc(10);
        $bookmarks: &;
    }

    &__user {
        margin-left: vw-calc(10);
        height: vw-calc(40);
        width: vw-calc(40);
        background-color: map-deep-get($colors, "gray", "default");
        background-position: center;
        background-size: cover;
        border-radius: 50%;
    }

    &__slide-title {
        display: inline-block;
        font-size: rem-calc(14);
        color: map-deep-get($colors, "black", "default");
        opacity: 0.5;
        font-weight: 600;
        padding: vw-calc(15);
    }
}
