.logoslide {
    margin: 0 10%;
    padding: vw-calc(80) 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__title {
        text-align: center;
        margin-bottom: vw-calc(10);
    }

    &__date {
        text-align: center;
        font-size: rem-calc(14);
        text-transform: uppercase;
        letter-spacing: rem-calc(2.5);
        color: map-deep-get($colors, "brand", "primary");
        margin-bottom: vw-calc(100);
    }

    &__seperator {
        margin: 0 auto;
        height: vw-calc(200);
        width: 1px;
        background: map-deep-get($colors, "gray", "default");
    }

    &__companylogo {
        float: right;
        margin: vw-calc(100) 0;
    }

    &__clientlogo {
        float: left;
        margin: vw-calc(100) 0;
        max-width: 200px;
    }
}