.textslide {
    padding-bottom: vw-calc(80);
    $elm: &;

    &--bg {
        height: 100vh;
        background: map-deep-get($colors, "brand", "primary");

        #{$elm}__titlearea {
            background: map-deep-get($colors, "white", "default");
        }

        #{$elm}__titlebottom {
            border-top-color: map-deep-get($colors, "white", "default");
        }

        #{$elm}__text {
            color: map-deep-get($colors, "white", "default");
        }
    }

    &__titlearea {
        padding-top: vw-calc(100);
        background: map-deep-get($colors, "gray", "light");
    }

    &__titlebottom {
        border-top: vw-calc(70) solid map-deep-get($colors, "gray", "light");
        border-right: 100vw solid transparent;
        margin-bottom: vw-calc(20);
    }

    &__caption {
        display: inline-block;
        margin-top: vw-calc(20);
        color: map-deep-get($colors, "brand", "primary");
    }

    &__intro {
        //font-size: rem-calc(20);
        line-height: rem-calc(30);
        font-style: italic;
        margin-bottom: vw-calc(40);
    }

    &__piechart {
        margin-top: 4rem;
    }

    &__text {

        /* stylelint-disable */
        ul li {
            position: relative;
            margin-left: vw-calc(20);

            &::before {
                position: absolute;
                left: vw-calc(-20);
                top: .5em;
                width: 0;
                height: 0;
                content: "";
                border-top: vw-calc(5.5) solid transparent;
                border-left: vw-calc(9) solid map-deep-get($colors, "brand", "primary");
                border-bottom: vw-calc(5.5) solid transparent;
            }
        }

        ol {
            padding-left: 1em;

            li {
                padding-left: .3em;
            }
        }
        /* stylelint-enable */

        &--small {
            font-size: rem-calc(16);
            line-height: rem-calc(24);
        }

        &--normal {
            font-size: rem-calc(20);
            line-height: rem-calc(30);
        }

        &--large {
            font-size: rem-calc(30);
            line-height: rem-calc(45);
        }
    }

    &__image {
        margin-top: vw-calc(-120);
        max-width: 100%;
    }

    &__image--big {
        max-width: 100%;
        max-height: 60vh;
    }
}
